import React, { useState, useEffect } from "react";

const styles = `
  @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');

  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Outfit', sans-serif;
  }

  .app-container {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease-in-out;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  .content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .logo {
    width: 80%;
    max-width: 300px;
    margin-bottom: 40px;
  }

  .button {
    width: 100%;
    max-width: 300px;
    padding: 15px 25px;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 16px;
    color: white;
    font-family: 'Outfit', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.2);
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: all 0.4s ease;
  }

  .button::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.1) 0%,
      transparent 70%
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .button:hover, .button:focus {
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
    transform: translateY(-3px);
    box-shadow: 0 12px 40px rgba(31, 38, 135, 0.3);
  }

  .button:hover::before {
    left: 100%;
  }

  .button:hover::after {
    opacity: 1;
  }

  .button:active {
    transform: translateY(1px);
    box-shadow: 0 4px 16px rgba(31, 38, 135, 0.2);
  }

  .button-icon-wrapper {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    margin-left: -10px;
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .button-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .progress-container {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    display: flex;
    gap: 5px;
    z-index: 3;
  }

  .progress-bar {
    flex-grow: 1;
    height: 2px;
    background: rgba(255, 255, 255, 0.3);
  }

  .progress {
    height: 100%;
    background: white;
    transition: width 5s linear;
  }

  @media (max-width: 300px) {
    .button {
      font-size: 16px;
      padding: 12px 20px;
    }
    .button-icon-wrapper {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      margin-left: -8px;
      margin-top: -8px;
      margin-bottom: -8px;
    }
  }
`;

const backgrounds = [
  "https://nmpp.aeonibrokersreward.com.my/wp-content/uploads/2024/08/shutterstock_1573265545.jpg",
  "https://nmpp.aeonibrokersreward.com.my/wp-content/uploads/2024/08/shutterstock_1573281406.jpg",
  "https://nmpp.aeonibrokersreward.com.my/wp-content/uploads/2024/08/shutterstock_1573281409.jpg",
];

const LinkButton = ({ text, link, icon, insuranceType }) => (
  <button 
    className="button" 
    onClick={() => window.open(link, "_blank")}
    data-insurance-type={insuranceType}
  >
    <div className="button-icon-wrapper">
      <img src={icon} alt={`${text} icon`} className="button-icon" />
    </div>
    {text}
  </button>
);

export default function App() {
  const [currentBg, setCurrentBg] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentBg((prev) => (prev + 1) % backgrounds.length);
      setProgress(0);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    setProgress(100);
  }, [currentBg]);

  const handleBackgroundClick = () => {
    setCurrentBg((prev) => (prev + 1) % backgrounds.length);
    setProgress(0);
  };

  return (
    <>
      <style>{styles}</style>
      <div className="app-container" onClick={handleBackgroundClick}>
        {backgrounds.map((bg, index) => (
          <div
            key={index}
            className="background"
            style={{
              backgroundImage: `url(${bg})`,
              opacity: index === currentBg ? 1 : 0,
            }}
          />
        ))}
        <div className="overlay"></div>
        <div className="progress-container">
          {backgrounds.map((_, index) => (
            <div key={index} className="progress-bar">
              <div
                className="progress"
                style={{
                  width:
                    index === currentBg
                      ? `${progress}%`
                      : index < currentBg
                      ? "100%"
                      : "0%",
                  transition: index === currentBg ? "width 5s linear" : "none",
                }}
              />
            </div>
          ))}
        </div>
        <div className="content">
          <img
            src="http://aeoninsurance.com.my/wp-content/uploads/2024/08/AEON-INSURANCE-BROKERS_white.png"
            alt="Aeon Insurance Logo"
            className="logo"
          />
          <LinkButton
            text="Car Insurance"
            link="https://getquote.allianz.com.my/motorcycle-plus/get-info?utm_source=AEONINS"
            icon="https://nmpp.aeonibrokersreward.com.my/wp-content/uploads/2024/08/car-Icon-01.png"
            insuranceType="car"
          />
          <LinkButton
            text="Travel Insurance"
            link="https://getquote.allianz.com.my/travel-care-plus/get-info?utm_source=AEONINS"
            icon="https://nmpp.aeonibrokersreward.com.my/wp-content/uploads/2024/08/travel-Icon-05.png"
            insuranceType="travel"
          />
          <LinkButton
            text="Life Insurance"
            link="https://fi.life/aeoninsurance"
            icon="https://nmpp.aeonibrokersreward.com.my/wp-content/uploads/2024/08/life-Icon-04.png"
            insuranceType="life"
          />
          <LinkButton
            text="Personal Accident"
            link="https://getquote.allianz.com.my/lifestyle-protect/get-info?utm_source=AEONINS"
            icon="https://nmpp.aeonibrokersreward.com.my/wp-content/uploads/2024/08/pa-Icon-03.png"
            insuranceType="personal-accident"
          />
        </div>
      </div>
    </>
  );
}
